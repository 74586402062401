/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:31fdf681-e880-42cc-bc05-44fb1ac14bfd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rQikbqHYQ",
    "aws_user_pools_web_client_id": "7q62enr1646oesl3avncp6pk94",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://34ieactolzcldnqpvl2lm4r7qi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://oegufycbfa.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "trulibucket142935-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "7d2e5679cb784ce7bd1a9598a07fc3d7",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
